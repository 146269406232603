import React, { useEffect, useState } from "react"
import Client from "shopify-buy"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const client = Client.buildClient({
  domain: `shop.moorilla.com.au`,
  storefrontAccessToken: `bec00cb5798d9a399ba8fea0879bbf2f`,
})

const initialState = {
  client,
  adding: { isAdding: false, addingId: "" },
  checkout: { lineItems: [] },
  products: [],
  dob: {
    value: "",
    passed: false,
    fullAttempted: false,
    successfulReturn: false,
  },
}

const GlobalContextProvider = ({ children }) => {
  const [state, updateState] = useState(initialState)

  useEffect(() => {
    const initCheckout = async () => {
      // Check for existing checkout
      const isBrowser = typeof window !== "undefined"
      const existingCheckoutID = isBrowser
        ? localStorage.getItem("shopifyCheckoutId")
        : null

      const setCheckoutInState = checkout => {
        if (isBrowser) {
          localStorage.setItem("shopifyCheckoutId", checkout.id)
        }

        updateState(previousState => {
          return { ...previousState, checkout }
        })
      }

      const createNewCheckout = () => state.client.checkout.create()
      const fetchCheckout = id => state.client.checkout.fetch(id)

      if (existingCheckoutID) {
        try {
          const checkout = await fetchCheckout(existingCheckoutID)
          //Check if checkout hasn't been compeeted yet
          if (!checkout.completedAt) {
            setCheckoutInState(checkout)
            return
          }
        } catch (e) {
          localStorage.setItem("shopifyCheckoutId", null)
        }
      }

      const newCheckout = await createNewCheckout()
      setCheckoutInState(newCheckout)
    }

    const initDOB = () => {
      const isBrowser = typeof window !== "undefined"
      const existingDOB = isBrowser ? sessionStorage.getItem("dob") : null

      //get value of DOB from session storage and update state
      if (existingDOB) {
        let dobFromStorage = JSON.parse(sessionStorage.getItem("dob"))

        updateState(previousState => {
          return {
            ...previousState,
            dob: {
              value: `${dobFromStorage.year}-${dobFromStorage.month}-${dobFromStorage.day}`,
              passed: dobFromStorage.passed,
              fullyAttempted: dobFromStorage.fullyAttempted,
              successfulReturn: dobFromStorage.successfulReturn,
            },
          }
        })
      }
    }

    initCheckout()
    initDOB()
  }, [state.client.checkout])

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider
        value={{
          addVariantToCart: lineItemsToAdd => {
            updateState(previousState => {
              return {
                ...previousState,
                adding: { isAdding: true, addingId: lineItemsToAdd },
              }
            })

            let checkoutId = localStorage.getItem("shopifyCheckoutId")

            state.client.checkout
              .addLineItems(checkoutId, lineItemsToAdd)
              .then(checkout => {
                updateState(previousState => {
                  return {
                    ...previousState,
                    checkout,
                    adding: { isAdding: false, addingId: "" },
                  }
                })
              })
          },
          removeLineItem: lineItemId => {
            let checkoutId = localStorage.getItem("shopifyCheckoutId")

            state.client.checkout
              .removeLineItems(checkoutId, [lineItemId])
              .then(checkout => {
                updateState(previousState => {
                  return { ...previousState, checkout }
                })
              })
          },
          updateLineItem: (lineItemId, quantity) => {
            let checkoutId = localStorage.getItem("shopifyCheckoutId")
            const lineItemsToUpdate = [
              {
                id: lineItemId,
                quantity: quantity,
              },
            ]

            state.client.checkout
              .updateLineItems(checkoutId, lineItemsToUpdate)
              .then(checkout => {
                // Do something with the updated checkout
                updateState(previousState => {
                  return { ...previousState, checkout }
                })
              })
          },
          clearDOB: () => {
            sessionStorage.removeItem("dob")
            updateState(previousState => {
              return {
                ...previousState,
                dob: { value: "", passed: false },
              }
            })
          },
          updateDOB: (year, month, day) => {
            let checkoutId = localStorage.getItem("shopifyCheckoutId")

            // checkout DOB
            let currYear = new Date().getFullYear()
            let currMonth = new Date().getMonth() + 1
            let currDay = new Date().getDate()

            let passed = false
            let fullyAttempted = false

            month = parseInt(month)
            day = parseInt(day)

            //under 18 math
            if (
              year &&
              month &&
              day &&
              month <= 12 &&
              day <= 31 &&
              year.toString().length === 4
            ) {
              fullyAttempted = true
              if (year + 18 < currYear) {
                passed = true
              } else if (year + 18 === currYear) {
                if (month < currMonth) {
                  passed = true
                } else if (month === currMonth) {
                  if (day <= currDay) {
                    passed = true
                  }
                }
              }
            }

            updateState(previousState => {
              return {
                ...previousState,
                dob: {
                  value: `${year}-${month}-${day}`,
                  passed,
                  fullyAttempted: true,
                },
              }
            })

            //add dob to session storage
            sessionStorage.setItem(
              "dob",
              JSON.stringify({
                year: year,
                month: month,
                day: day,
                passed: passed,
                fullyAttempted: fullyAttempted,
              })
            )

            //update dob in shopify checkout
            if (passed) {
              // set DOB
              const input = {
                customAttributes: [
                  { key: "dob", value: `${year}-${month}-${day}` },
                ],
              }
              client.checkout
                .updateAttributes(checkoutId, input)
                .then(checkout => {
                  sessionStorage.setItem(
                    "dob",
                    JSON.stringify({
                      year: year,
                      month: month,
                      day: day,
                      passed: passed,
                      fullyAttempted: fullyAttempted,
                      successfulReturn: true,
                    })
                  )
                  updateState(previousState => {
                    return {
                      ...previousState,
                      checkout,
                      dob: {
                        ...previousState.dob,
                        passed,
                        fullyAttempted: true,
                        successfulReturn: true,
                      },
                    }
                  })
                })
            }
          },
        }}
      >
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
