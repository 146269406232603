import React from "react"
import GlobalContextProvider from "./src/context/GlobalContextProvider"
import cookie from "js-cookie"

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  // wrap inside a timeout to ensure the title has properly been changed
  if (prevLocation !== null) {
    setTimeout(() => {
      window.dataLayer.push({
        event: "custom.route.change",
        route: {
          current: location.pathname,
          previous: prevLocation ? prevLocation.pathname : null,
          title: document.title,
        },
      })
    }, 50)

    cookie.set("previousPage", prevLocation.pathname, { expires: 1 / 24 })
  }
}
